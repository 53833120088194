/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

.tiptap {
  outline: 0;
  min-width: 100%;

  @apply px-4;
  //   prose-sm sm:prose lg:prose-md xl:prose-2xl
  @apply prose;

  p {
    @apply my-4;
  }

  ul {
    @apply my-4;
  }

  li {
    @apply p-0;
    p {
      @apply m-0;
    }
  }

  ul[data-type="taskList"] {
    list-style: none;
    margin-left: 0;
    padding: 0;

    li {
      align-items: flex-start;
      display: flex;

      > label {
        flex: 0 0 auto;
        margin-right: 0.5rem;
        user-select: none;
      }

      > div {
        flex: 1 1 auto;
      }
    }

    li[data-checked="true"] {
      > div {
        text-decoration: line-through;
      }
    }

    input[type="checkbox"] {
      cursor: pointer;
      @apply checkbox;
      height: 1.25rem;
      width: 1.25rem;
      vertical-align: middle;
    }

    ul[data-type="taskList"] {
      margin: 0;
    }
  }
}

.tiptap {
  :first-child {
    margin-top: 0;
  }

  pre {
    border-radius: 0.5rem;
    font-family: "JetBrainsMono", monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }

    /* Code styling */
    .hljs-comment,
    .hljs-quote {
      color: #616161;
    }

    .hljs-variable,
    .hljs-template-variable,
    .hljs-attribute,
    .hljs-tag,
    .hljs-name,
    .hljs-regexp,
    .hljs-link,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class {
      color: #f98181;
    }

    .hljs-number,
    .hljs-meta,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-literal,
    .hljs-type,
    .hljs-params {
      color: #fbbc88;
    }

    .hljs-string,
    .hljs-symbol,
    .hljs-bullet {
      color: #b9f18d;
    }

    .hljs-title,
    .hljs-section {
      color: #faf594;
    }

    .hljs-keyword,
    .hljs-selector-tag {
      color: #70cff8;
    }

    .hljs-emphasis {
      font-style: italic;
    }

    .hljs-strong {
      font-weight: 700;
    }
  }
  :first-child {
    margin-top: 0;
  }

  img {
    display: block;
    height: auto;
    margin: 1.5rem 0;
    max-width: 100%;

    &.ProseMirror-selectednode {
      // outline: 3px solid red;
      @apply outline-accent outline-2 outline-double;
    }
  }
}

.higlight {
  @apply text-accent-content bg-accent;
}
